<template>
    <div class="overlay open"></div>
    <div class="message-error" >
    <svg class="close" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6561 1.33776L6.99387 6L11.6561 10.6622C11.788 10.7941 11.8621 10.973 11.8621 11.1595C11.8621 11.3461 11.788 11.525 11.6561 11.6569C11.5242 11.7887 11.3453 11.8628 11.1588 11.8628C10.9723 11.8628 10.7934 11.7887 10.6615 11.6569L5.99926 6.99461L1.33701 11.6569C1.20512 11.7887 1.02623 11.8628 0.839707 11.8628C0.653182 11.8628 0.474295 11.7887 0.342401 11.6569C0.210508 11.525 0.136411 11.3461 0.136411 11.1595C0.136411 10.973 0.210508 10.7941 0.342401 10.6622L5.00464 6L0.342402 1.33776C0.210508 1.20586 0.13641 1.02698 0.13641 0.840451C0.13641 0.653925 0.210508 0.475039 0.342401 0.343146C0.474295 0.211252 0.653181 0.137154 0.839707 0.137154C1.02623 0.137154 1.20512 0.211252 1.33701 0.343146L5.99926 5.00539L10.6615 0.343146C10.7934 0.211252 10.9723 0.137155 11.1588 0.137155C11.3453 0.137155 11.5242 0.211252 11.6561 0.343146C11.788 0.475039 11.8621 0.653925 11.8621 0.840451C11.8621 1.02698 11.788 1.20586 11.6561 1.33776Z" fill="#052C57"/>
    </svg> 
    <svg class="auto" width="80" height="92" viewBox="0 0 80 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M79.099 16.4918L40.0006 1L0.902158 16.4918C0.902158 16.4918 -1.63516 49.7595 8.27921 66.6557C17.3821 82.169 40.0006 91 40.0006 91C40.0006 91 62.619 82.169 71.7219 66.6557C81.6363 49.7595 79.099 16.4918 79.099 16.4918Z" fill="#0349A8" stroke="black" stroke-width="0.1"/>
        <path d="M29.373 35.6719L50.0283 56.3271" stroke="white" stroke-width="5" stroke-linecap="round"/>
        <path d="M50.0293 35.6719L29.3741 56.3271" stroke="white" stroke-width="5" stroke-linecap="round"/>
    </svg> 
    <span>{{ this.$i18n.locale == 'ar' ? 
    'لم يتم إرسال الطلب !'
    :
    'The request was not sent!' 
    }}
    </span>
    </div>
</template>
<script>
export default {
 data() {
   return {
   };
 }

};
</script>